import { useRef, useState } from 'react';
import { Alert, Button, Modal, ModalBody, Box, Link, Text, Heading, Flex } from '@mezzoforte/forge';
import { X } from '@mezzoforte/forge-icons';
import { AbsoluteCenter, Divider } from '@chakra-ui/react';
import { usePathname } from 'next/navigation';
import { useSession } from '@/hooks/useSession';
import { gtmService } from '@/util/gtm-service';
import type { LoginAPIRequest } from '@/types/ApiRequest';
import { NextLinkButton } from '@/components/Link/LinkButton';
import { LoginForm } from '@/components/Auth/LoginForm';
import { MfaForm } from '@/components/Auth/MfaForm';
import { CustomerService } from '@/components/CustomerService/CustomerService';
import {
  getWidget as getFreshchatWidget,
  updateSessionIdentifier as updateFreshchatSessionIdentifier,
  updateLoggedInState as updateFreshchatLoggedInState,
} from '@/features/Freshchat/FreshchatScript';
import { useApi } from '@/hooks/useApi';
import { identificationService } from '@/features/Identification/identification-service';

export function LoginModal({
  isOpen,
  onDismiss,
  onSuccess = () => {},
}: {
  isOpen: boolean;
  onDismiss: () => void;
  onSuccess: () => void;
}) {
  const { login: loginMutation } = useSession();
  const initialFocusRef = useRef(null);
  const [formState, setFormState] = useState<'credentials' | 'mfa'>('credentials');
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const { apiClient } = useApi();

  function updateFreshchatProperties() {
    const widget = getFreshchatWidget();
    if (!widget) {
      return;
    }

    updateFreshchatLoggedInState(widget, true);
    if (widget.isOpen()) {
      void updateFreshchatSessionIdentifier(apiClient, widget);
    }
  }

  const submitLogin = (credentials: LoginAPIRequest) => {
    setCredentials(credentials);
    loginMutation.mutate(credentials, {
      onSuccess: (response) => {
        if (response.promptTwoFactor) {
          setFormState('mfa');
          return;
        }

        gtmService.recommended.login('Password');
        onSuccess?.();
        onDismiss?.();
        updateFreshchatProperties();
      },
    });
  };

  const submitMfa = (passcode: string) => submitLogin({ ...credentials, passcode });
  const pathname = usePathname();

  const onClose = () => {
    loginMutation.reset();
    onDismiss?.();
  };

  // Not ideal, but we need to manually create a modal header in order to have working password managers.
  // Chakra UI modal steals focus to the close button and this prevents browser addons like 1Password from
  // working correctly. If this issue is fixed upstream there is no longer a need for a custom modal header.
  const renderModalHeader = () => (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      mb={3}
      px={4}
    >
      <Heading
        variant="h3"
        as="h2"
      >
        Kirjaudu sisään
      </Heading>
      <Button
        icon={<X />}
        aria-label="Sulje"
        onClick={onDismiss}
        tabIndex={-1}
      />
    </Flex>
  );

  const renderLoginErrorAlert = () => {
    if (loginMutation.error?.banned) {
      return (
        <Alert
          variant="danger"
          title="Käyttäjän kirjautuminen on estetty"
          mb={5}
          px={4}
          data-test="auth-alert"
        >
          <Text as="p">Ongelmatilanteissa voit olla yhteydessä asiakaspalveluumme:</Text>
          <CustomerService />
        </Alert>
      );
    }

    if (loginMutation.error?.isPasswordExpired) {
      return (
        <Alert
          variant="danger"
          title="Salasana on vaihdettava ennen kuin voit kirjautua sisään"
          mb={5}
          px={4}
          data-test="auth-alert"
        >
          <Text as="p">Saat vaihdettua salasanasi sähköpostiisi lähetetyn linkin avulla.</Text>
          <Text as="p">Ongelmatilanteissa voit olla yhteydessä asiakaspalveluumme:</Text>
          <CustomerService />
        </Alert>
      );
    }

    if (loginMutation.error?.ipRestriction) {
      return (
        <Alert
          variant="warning"
          title="Kirjautuminen epäonnistui"
          mb={5}
          px={4}
          data-test="auth-alert"
        >
          <Text as="p">
            Ylläpitäjänä voit kirjautua vain sallituista IP-osoitteista. Kokeile yhdistämistä VPN-verkkoon.
          </Text>
        </Alert>
      );
    }

    if (loginMutation.error?.deletionOngoing) {
      return (
        <Alert
          variant="warning"
          title="Käyttäjän poisto on käynnissä"
          mb={5}
          px={4}
          data-test="auth-alert"
        >
          <Text as="p">Ongelmatilanteissa voit olla yhteydessä asiakaspalveluumme:</Text>
          <CustomerService />
        </Alert>
      );
    }

    return (
      <Alert
        showIcon={false}
        variant="warning"
        title="Kirjautuminen epäonnistui"
        mb={5}
        data-test="auth-alert"
      >
        <Link href="/unohditko-salasanasi">Ongelmia kirjautumisessa?</Link>
      </Alert>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onClose}
      initialFocusRef={initialFocusRef}
      variant="custom"
      size={{ base: 'xs', sm: 'sm', lg: 'lg', xl: 'xl' }}
    >
      {renderModalHeader()}
      <ModalBody>
        {loginMutation.isError && renderLoginErrorAlert()}
        <Box>
          {formState === 'credentials' && (
            <LoginForm
              onSubmit={submitLogin}
              ref={initialFocusRef}
              {...loginMutation}
            />
          )}
          {formState === 'mfa' && (
            <MfaForm
              onSubmit={submitMfa}
              {...loginMutation}
            />
          )}
        </Box>
        <Box
          position="relative"
          padding="5"
          color="rgba(117, 117, 117, 1)"
        >
          <Divider />
          <AbsoluteCenter
            bg="white"
            px="4"
          >
            tai
          </AbsoluteCenter>
        </Box>
        {loginMutation.isError && (
          <NextLinkButton
            onClick={onClose}
            href={identificationService.getIdentificationUrl('login', pathname ?? '/')}
            variant="default-hero"
            width="100%"
            mb={2}
          >
            Kirjaudu tunnistautumalla
          </NextLinkButton>
        )}

        <NextLinkButton
          onClick={onClose}
          href="/tee-tunnus"
          variant="default-hero"
          width="100%"
        >
          Ei tunnusta vielä? Tee ilmainen tunnus
        </NextLinkButton>
      </ModalBody>
    </Modal>
  );
}
