'use client';

import React, { HTMLProps } from 'react';
import { Button } from '@mezzoforte/forge';
import { PrismicLink } from '@prismicio/react';
import NextLink from '@/components/Link/Link';

export const wrapButtonWithLink = <LinkProps extends object>(
  LinkComponent: React.ComponentType<LinkProps>
): React.FC<LinkProps & React.ComponentProps<typeof Button> & HTMLProps<HTMLAnchorElement>> =>
  function ButtonLink({
    textAlign,
    isDisabled,
    ...props
  }: LinkProps & React.ComponentProps<typeof Button> & HTMLProps<HTMLAnchorElement>) {
    return (
      <LinkComponent
        {...(props as LinkProps)}
        style={{ textDecoration: 'none' }}
      >
        <Button
          {...props}
          isDisabled={isDisabled}
          textAlign={textAlign}
          as="span"
        />
      </LinkComponent>
    );
  };

export const NextLinkButton = wrapButtonWithLink(NextLink);
export const PrismicLinkButton = wrapButtonWithLink(PrismicLink);
