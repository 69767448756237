import { useCallback, useMemo } from 'react';
import axios, { Axios, isAxiosError } from 'axios';
import { useToast } from '@mezzoforte/forge';
import { getHuutokaupatApiBaseUrl } from '@/util/base-url';

interface UseApi {
  readonly apiClient: Axios;
  readonly apiFetch: (url: string, method?: string) => Promise<Response>;
}

export function useApi(): UseApi {
  const { playToast } = useToast();
  const apiClient = useMemo(() => {
    const client = axios.create({
      baseURL: getHuutokaupatApiBaseUrl(),
      withCredentials: true,
      xsrfCookieName: 'csrf-token',
      xsrfHeaderName: 'X-Csrf-Token',
      headers: {
        'Cache-Control': 'no-cache',
      },
    });
    client.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (axios.isCancel(error)) {
          return Promise.resolve();
        }
        if (isAxiosError(error) && error.response?.status === 429) {
          playToast('Virhe', 'Liikaa pyyntöjä. Odota hetki ja yritä sen jälkeen uudestaan.', {
            toastId: 'too-many-requests-error',
            variant: 'danger',
            closeManually: true,
          });
        }
        return Promise.reject(error);
      }
    );
    return client;
  }, [playToast]);

  const apiFetch = useCallback(
    (url: string, method = 'GET'): Promise<Response> =>
      fetch(`${getHuutokaupatApiBaseUrl()}${url}`, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: 'application/json',
        },
      }),
    []
  );

  return { apiClient, apiFetch };
}
