import Script from 'next/script';
import { useEffect, useRef } from 'react';
import { Axios } from 'axios';
import { useSession } from '@/hooks/useSession';
import { User } from '@/types/User';
import {
  FaqTagsConfig,
  FreshchatWidget,
  FreshchatWidgetConfig,
  UserProperties,
} from '@/features/Freshchat/Freshchat.types';
import freshchatConfig from '@/features/Freshchat/freshchatConfig';
import { useCookieConsent } from '@/hooks/useCookieConsent';
import { useApi } from '@/hooks/useApi';

const freshChatScripts = {
  main: 'https://wchat.eu.freshchat.com/js/widget.js',
};

const vehicleTagPages = [
  'ajoneuvot-ja-tarvikkeet',
  'henkiloautot',
  'pakettiautot',
  'kevytkuorma-autot',
  'veneet',
  'moottoripyorat',
  'asuntovaunut-perakarryt',
  'moottorikelkat-ja-monkijat',
  'muut-ajoneuvot',
  'ajoneuvovaraosat',
  'ajoneuvotarvikkeet',
];

const sellerTagPages = ['yritystili', 'ilmoittaminen', 'ilmoittajaksi', 'mukaan-liittyminen'];

export const shouldDisplayWidget = (user?: User) => !user?.isAdmin;

export const getFaqTags = ({ pathname }: Location, user?: User | null): FaqTagsConfig => {
  const vehicleRegex = new RegExp(`^/(${vehicleTagPages.join('|')})$`);

  const shouldAddVehicleTag = vehicleRegex.test(pathname);
  const shouldAddKuhuTag = /^\/kuluttajat-autohuutokauppa$/.test(pathname);
  const shouldAddKuhu2Tag = /^\/kuluttajahuutokaupat$/.test(pathname);
  const shouldAddApartmentTag = /^\/asunnot$/.test(pathname);
  const shouldAddBecomeSellerTag = !user && /^\/myy-huutokaupatcomissa$/.test(pathname);

  const tags = [
    user?.isSeller ? 'ilmoittaminen' : '',
    user?.isBuyer ? 'ostaja' : '',
    shouldAddVehicleTag ? 'ajoneuvot' : '',
    shouldAddKuhuTag || shouldAddKuhu2Tag ? 'kuluttajahuutokaupat' : '',
    shouldAddApartmentTag ? 'asunnon myynti' : '',
    shouldAddBecomeSellerTag ? 'ilmoittajaksi' : '',
  ].filter(Boolean);

  if (!tags.length) {
    return {};
  }

  return {
    tags,
    filterType: 'article',
  };
};

export const getTags = ({ pathname }: Location) => {
  const sellerRegex = new RegExp(`^/(${sellerTagPages.join('|')})$`);
  const vehicleRegex = new RegExp(`^/(${vehicleTagPages.join('|')})$`);

  const shouldAddSellerTag = sellerRegex.test(pathname);
  const shouldAddVehicleTag = vehicleRegex.test(pathname);

  return ['kalevi-botti', shouldAddSellerTag ? 'ilmoittajaksi' : '', shouldAddVehicleTag ? 'ajoneuvot' : ''].filter(
    Boolean
  );
};

export const getConfig = (baseConfig: FreshchatWidgetConfig, location: Location, user?: User | null) => ({
  ...baseConfig,
  faqTags: getFaqTags(location, user),
  tags: getTags(location),
  ...(user
    ? {
        externalId: user.uuid,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phoneNumber,
      }
    : {}),
});

async function setUserProperties(widget: FreshchatWidget, properties: UserProperties) {
  const res = await widget.user.setProperties(properties);
  if (res.status !== 200) {
    throw new Error(`Failed to set user properties: ${res.status}`);
  }
}

export async function updateSessionIdentifier(client: Axios, widget: FreshchatWidget) {
  const response = await client.get<{ identifier: string }>('/api/chat/open');
  void setUserProperties(widget, { identifier: response.data.identifier });
}

export function updateLoggedInState(widget: FreshchatWidget, state: boolean) {
  void setUserProperties(widget, { loggedIn: state });
}

function initializeProperties(client: Axios, widget: FreshchatWidget, user?: User | null) {
  updateLoggedInState(widget, !!user);
  if (user !== undefined && widget.isOpen()) {
    void updateSessionIdentifier(client, widget);
  }
}

export function getWidget() {
  return window.fcWidget;
}

export function FreshchatScript(props: { onLoad?: () => void }) {
  const {
    currentUser: { data: session },
  } = useSession();
  const { cookieConsent } = useCookieConsent();
  const { apiClient } = useApi();

  const userRef = useRef(session?.user);

  useEffect(() => {
    userRef.current = session?.user;
  }, [session?.user]);

  const onChatLoad = () => {
    const widget = getWidget();
    if (widget === undefined) {
      console.error('Freshchat script was loaded but no fcWidget was found in window');
      return;
    }

    const user = session?.user;

    widget.init(getConfig(freshchatConfig, location, user));
    widget.setTags(getTags(location));
    widget.setFaqTags(getFaqTags(location, user));
    initializeProperties(apiClient, widget, session?.user);
    props.onLoad?.();

    widget.on('widget:opened', () => {
      // Use userRef.current to ensure the callback uses the latest user data
      updateLoggedInState(widget, !!userRef.current);
      if (userRef.current) {
        void updateSessionIdentifier(apiClient, widget);
      }
    });
  };

  if (!cookieConsent.freshchat) return null;

  return (
    <Script
      src={freshChatScripts.main}
      onLoad={onChatLoad}
    />
  );
}
